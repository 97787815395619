import { YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
    props: {
        sku: {
            type: [Number, String],
            default: () => {
            }
        }
    },
    data: () => ({
        page: 1,
        totalPage: 1,
        totalItem: 0,
        totalAvailable: 0,
        items: [],
        filters: {},
        yesNoOptions: [...YES_NO_OPTIONS],
        statusOption: [],
        statusTxt: {},
        sourceOption: [],
        sourceTxt: {},
        isLoading: false,
        rollUidDialog: false,
        updatingItem: {},
        sum: {},
        qrScanDialog: false,
        qrScanType: null,
        historyDialog: false,
    }),
    watch: {
        page() {
            this.getList()
        },
    },
    created() {
        if (this.sku) {
            this.filters = this.filters = { ...this.filters, sku: this.sku }
        }
        const sku = this.getUrlParameter('sku')
        const customer_goods_barcode = this.getUrlParameter('barcode')
        if (sku) {
            this.filters = { ...this.filters, sku }
        }
        if (customer_goods_barcode) {
            this.filters = { ...this.filters, customer_goods_barcode }
        }
        const statusOption = [
            {
                text: this.$t('labels.uid_status_1'),
                value: 1
            },
            {
                text: this.$t('labels.uid_status_2'),
                value: 2
            },
            {
                text: this.$t('labels.uid_status_3'),
                value: 3
            },
            {
                text: this.$t('labels.uid_status_4'),
                value: 4
            },
            {
                text: this.$t('labels.uid_status_5'),
                value: 5
            },
            {
                text: this.$t('labels.uid_status_6'),
                value: 6
            },
            {
                text: this.$t('labels.uid_status_7'),
                value: 7
            },
            {
                text: this.$t('labels.uid_status_0'),
                value: 0
            },
        ]
        const statusTxt = {
            1: this.$t('labels.uid_status_1'),
            2: this.$t('labels.uid_status_2'),
            3: this.$t('labels.uid_status_3'),
            4: this.$t('labels.uid_status_4'),
            5: this.$t('labels.uid_status_5'),
            6: this.$t('labels.uid_status_6'),
            7: this.$t('labels.uid_status_7'),
            0: this.$t('labels.uid_status_0'),
        }
        const sourceOption = [
            {
                text: this.$t('labels.uid_source_11'),
                value: 11
            },
            {
                text: this.$t('labels.uid_source_12'),
                value: 12
            },
            {
                text: this.$t('labels.uid_source_13'),
                value: 13
            },
            {
                text: this.$t('labels.uid_source_14'),
                value: 14
            },
        ]
        const sourceTxt = {
            11: this.$t('labels.uid_source_11'),
            12: this.$t('labels.uid_source_12'),
            13: this.$t('labels.uid_source_13'),
            14: this.$t('labels.uid_source_14'),
        }
        this.statusOption = [...statusOption]
        this.sourceOption = [...sourceOption]
        this.statusTxt = { ...statusTxt }
        this.sourceTxt = { ...sourceTxt }
    },
    methods: {
        showHistoryDialog(item) {
            this.updatingItem = { ...item }
            this.historyDialog = true
        },
        hideHistoryDialog() {
            this.updatingItem = {}
            this.historyDialog = false
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
        showQRCodeScan(type) {
            this.qrScanDialog = true
            this.qrScanType = type
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
            this.qrScanType = null
        },
        onQRScanned(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
            this.getList()
        },
        showRollUid(item) {
            this.updatingItem = { ...item }
            this.rollUidDialog = true
        },
        hideRollUid() {
            this.updatingItem = {}
            this.rollUidDialog = false
        },
        downloadStamp(item) {
            this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-one-stamp`, {
                id: item.id,
            }, `50x20_${item.sku}_${item.id}.pdf`)
        },
        getList: debounce(function () {
            if (this.isMobile() && !this.filters.keywords) {
                return false
            }
            /*if (this.filters.keywords && !this.filters.keywords.includes('@')) {
                this.filters = {...this.filters, keywords: null}
                return false
            }*/
            httpClient.post('/uid-list', { ...this.filters, page: this.page }).then(({ data }) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = { ...data.sum }
                this.totalItem = data.total
                this.totalAvailable = data.total_available

                if (this.isMobile()) {
                    this.filters = { ...this.filters, keywords: null }
                }
            })
        }, 1000),
        onFilterChange(filter) {
            if (filter.name === 'sku') {
                let valueSpl = filter.value && filter.value.split('@') || ['']
                this.filters = { ...this.filters, [filter.name]: valueSpl[0] }
            } else {
                this.filters = { ...this.filters, [filter.name]: filter.value }
            }
        },
        onSortChange(sort) {
            this.filters = { ...this.filters, ...sort }
        },
        async exportExcel() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.waiting_file_download'))
                return false
            }
            this.isLoading = true
            const filename = 'ds-uid.xlsx'

            try {
                await this.downloadExcelFile('/uid-list-export', { ...this.filters }, filename)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
            }
        },
    },
}
