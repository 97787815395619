<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader" class="text-center">
              <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_pos" sort-name="pos_code"
                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th> -->
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :default-value="filters.customer_goods_barcode"
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :default-value="filters.keywords"
                :label="$t('labels.uid')"
                :placeholder="$t('labels.uid')"
                name="keywords"
                sort-name="keywords"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                label="Code | IMEI"
                placeholder="Code | IMEI"
                name="barcode"
                sort-name="barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="statusOption"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter
                :label="$t('labels.import')"
                :placeholder="$t('labels.import')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.employee_receipt')"
                :placeholder="$t('labels.employee_receipt')"
                name="employee_receipt"
                sort-name="employee_receipt"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.tracking_receipt')"
                :placeholder="$t('labels.tracking_receipt')"
                name="tracking_receipt"
                sort-name="tracking_receipt"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.order_pos')"
                :placeholder="$t('labels.order_pos')"
                name="order_pos_tracking"
                sort-name="order_pos_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.order_online')"
                :placeholder="$t('labels.order_online')"
                name="order_online_tracking"
                sort-name="order_online_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter
                :label="$t('labels.expired_date')"
                :placeholder="$t('labels.expired_date')"
                name="expired_date"
                sort-name="expired_date"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.lot_tracking')"
                :placeholder="$t('labels.lot_tracking')"
                name="lot_tracking"
                sort-name="lot_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.basket')"
                :placeholder="$t('labels.basket')"
                name="basket_code"
                sort-name="basket_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.position')"
                :placeholder="$t('labels.position')"
                name="pos_location_cell_id"
                sort-name="pos_location_cell_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`${key}_${item.id}`"
            class="text-center"
          >
            <!-- <td>{{ item.pos_code }}</td> -->
            <td>{{ item.customer_goods_barcode }}</td>
            <td>
              <span
                v-if="!item.barcode"
                class="text-decoration-underline primary--text cursor-pointer"
                @click="showHistoryDialog(item)"
              >
                {{ `${item.sku}@${item.uid}` }}
              </span>
            </td>
            <td>
              <span
                class="text-decoration-underline primary--text cursor-pointer"
                @click="showHistoryDialog(item)"
              >
                {{ item.barcode }}
              </span>
            </td>
            <td>
              <span
                v-if="
                  checkPermission(['goods_uid_roll', 'warehouse_admin']) &&
                  [1, 2, 3].includes(item.status)
                "
                class="error--text text-decoration-underline cursor-pointer"
                @click="showRollUid(item)"
              >
                {{ item.available ? "Yes" : "No" }}
              </span>
              <span v-else>{{ item.available ? "Yes" : "No" }}</span>
            </td>
            <td>{{ statusTxt[item.status] }}</td>
            <td>
              {{
                item.pos_receipted_at
                  ? formatDateTime(item.pos_receipted_at)
                  : ""
              }}
            </td>
            <td>{{ item.receipt_employee_name }}</td>
            <td>{{ item.tracking_id }}</td>
            <td>{{ item.order_pos_tracking }}</td>
            <td><OrderTracking :tracking-id="item.order_online_tracking" /></td>
            <td>
              {{
                item.expired_date
                  ? formatDateTime(item.expired_date, "DD/MM/YYYY")
                  : ""
              }}
            </td>
            <td>{{ item.lot_tracking }}</td>
            <td>{{ item.basket_code }}</td>
            <td>{{ item.pos_location_cell_id }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div
          class="d-flex table-footer-count mt-3"
          v-if="filters.sku || filters.customer_goods_barcode"
        >
          <div class="mr-2">
            {{ $t("labels.sum") }}: <b>{{ formatNumber(totalItem) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.available") }}:
            <b>{{ formatNumber(totalAvailable) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col sm="3">
        <div class="d-flex align-center justify-end">
          <v-btn
            color="success"
            @click="exportExcel"
            v-if="
              checkPermission(['goods_uid_manager']) &&
              (filters.sku || filters.customer_goods_barcode)
            "
            style="height: 40px"
            class="mr-2"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("labels.excel") }}
          </v-btn>
          <div style="width: 100px">
            <v-text-field
              dense
              outlined
              hide-details
              v-model.number="page"
              :label="$t('labels.page')"
              :placeholder="$t('labels.page')"
            ></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="rollUidDialog" persistent max-width="350px">
      <RollUid
        v-if="rollUidDialog"
        :item="updatingItem"
        @cancel="hideRollUid"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="historyDialog" persistent max-width="1024px">
      <UidHistory
        v-if="historyDialog"
        :item="updatingItem"
        @close="hideHistoryDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import uid from "@/components/pos_goods/mixins/uid";

export default {
  name: "Uid",
  components: {
    // SelectPos: () => import("@/components/table/SelectPos"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    RollUid: () => import("@/components/pos_goods/RollUid"),
    UidHistory: () => import("@/components/pos_goods/UidHistory"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  mixins: [uid],
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
